import React from 'react';
import AngularFriendsCarousel from '../homePage/AngularFriendsCarousel';
import ReactFriendsCarousel from '../homePage/ReactFriendsCarousel';

const FriendCarouselFeedItem = ({
  isReactFriendsCarouselEnabled
}: {
  isReactFriendsCarouselEnabled: boolean;
}): JSX.Element => {
  if (isReactFriendsCarouselEnabled) {
    return <ReactFriendsCarousel />;
  }
  return <AngularFriendsCarousel />;
};

export default FriendCarouselFeedItem;

import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { TGameData } from '../../common/types/bedev1Types';
import { TBuildEventProperties } from '../../common/components/GameTileUtils';
import GameTileTypeMap from '../../common/components/GameTileTypeMap';
import ScrollerBar from './ScrollerBar';
import { PageContext } from '../../common/types/pageContext';
import configConstants from '../../common/constants/configConstants';
import { useHorizontalScrollTracker } from '../../common/components/useHorizontalScrollTracker';
import {
  TComponentType,
  TGameSort,
  TPlayerCountStyle,
  TPlayButtonStyle
} from '../../common/types/bedev2Types';
import { getSortTargetId } from '../../omniFeed/utils/gameSortUtils';

type TGamesPageGameCarouselProps = {
  gameData: TGameData[];
  sort: TGameSort;
  positionId: number;
  page: PageContext.GamesPage;
  gamesContainerRef: React.RefObject<HTMLUListElement>;
  buildEventProperties: TBuildEventProperties;
  loadMoreGames?: () => void;
  isLoadingMoreGames: boolean;
  componentType?: TComponentType;
  playerCountStyle?: TPlayerCountStyle;
  playButtonStyle?: TPlayButtonStyle;
  itemsPerRow?: number;
  translate: WithTranslationsProps['translate'];
};

const {
  numGameCarouselLookAheadWindows,
  gameTileGutterWidth,
  wideGameTileGutterWidth
} = configConstants.gamesPage;

export const GameCarouselHorizontalScroll = ({
  gameData,
  sort,
  positionId,
  page,
  gamesContainerRef,
  buildEventProperties,
  loadMoreGames,
  isLoadingMoreGames,
  componentType,
  playerCountStyle,
  playButtonStyle,
  itemsPerRow,
  translate
}: TGamesPageGameCarouselProps): JSX.Element => {
  const carouselScrollRef = useRef<HTMLDivElement>(null);

  const tileRef = useRef<HTMLDivElement>(null);

  const [cursorIndex, setCursorIndex] = useState<number>(0);

  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const [isScrollBackDisabled, setIsScrollBackDisabled] = useState<boolean>(true);
  const [isScrollForwardDisabled, setIsScrollForwardDisabled] = useState<boolean>(true);

  const [carouselWindowWidth, setCarouselWindowWidth] = useState<number | undefined>();
  const [carouselLeftValue, setCarouselLeftValue] = useState<number>(0);

  const gameTileWidthOffset = useMemo(() => {
    return componentType === (TComponentType.GridTile || componentType === TComponentType.EventTile)
      ? wideGameTileGutterWidth
      : gameTileGutterWidth;
  }, [componentType]);

  const carouselRef = useCallback((node: HTMLDivElement) => {
    const handleResize = () => {
      const carouselWidth = node?.getBoundingClientRect()?.width;
      if (carouselWidth) {
        setCarouselWindowWidth(carouselWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const numGamesFitted = useMemo(() => {
    if (
      (componentType === TComponentType.GridTile || componentType === TComponentType.EventTile) &&
      itemsPerRow
    ) {
      return itemsPerRow;
    }

    const gameTileWidth = tileRef?.current?.getBoundingClientRect()?.width;

    if (carouselWindowWidth !== undefined && gameTileWidth !== undefined) {
      return Math.max(
        1,
        Math.floor(
          (carouselWindowWidth + gameTileWidthOffset) / (gameTileWidth + gameTileWidthOffset)
        )
      );
    }

    return 0;
  }, [carouselWindowWidth, gameTileWidthOffset, itemsPerRow, componentType]);

  useEffect(() => {
    if (carouselLeftValue >= 0) {
      setIsScrollBackDisabled(true);
    } else {
      setIsScrollBackDisabled(false);
    }

    if (isLoadingMoreGames) {
      setIsScrollForwardDisabled(true);
    } else {
      const carouselScrollWidth = carouselScrollRef?.current?.getBoundingClientRect().width;

      if (
        carouselWindowWidth !== undefined &&
        carouselScrollWidth !== undefined &&
        Math.abs(carouselLeftValue) + carouselWindowWidth >= carouselScrollWidth
      ) {
        // Another scroll wouold be off the end of the games
        setIsScrollForwardDisabled(true);
      } else {
        setIsScrollForwardDisabled(false);
      }
    }
  }, [carouselLeftValue, carouselWindowWidth, gameData?.length, isLoadingMoreGames]);

  const checkLoadMoreGames = useCallback(() => {
    const lookAheadGames = numGameCarouselLookAheadWindows * numGamesFitted;

    if (cursorIndex + lookAheadGames >= gameData?.length && loadMoreGames && !isLoadingMoreGames) {
      loadMoreGames();
    }
  }, [cursorIndex, numGamesFitted, loadMoreGames, isLoadingMoreGames, gameData?.length]);

  const getScrollDistance = useCallback((): number => {
    const gameTileWidth = tileRef?.current?.getBoundingClientRect()?.width;

    if (gameTileWidth === undefined) {
      return 0;
    }

    return numGamesFitted * (gameTileWidth + gameTileWidthOffset);
  }, [numGamesFitted, gameTileWidthOffset]);

  const onScrollToPrev = useCallback(() => {
    if (!isScrollBackDisabled) {
      const scrollDistance = getScrollDistance();

      setCarouselLeftValue(prevLeftValue => Math.min(prevLeftValue + scrollDistance, 0));
      setCursorIndex(prevCursorIndex => prevCursorIndex - numGamesFitted);
    }
  }, [getScrollDistance, isScrollBackDisabled, numGamesFitted]);

  const onScrollToNext = useCallback(() => {
    if (!isScrollForwardDisabled) {
      const scrollDistance = getScrollDistance();

      setCarouselLeftValue(prevLeftValue => {
        if (carouselScrollRef?.current) {
          const carouselScrollWidth = carouselScrollRef.current.getBoundingClientRect()?.width;

          return Math.max(prevLeftValue - scrollDistance, -1 * carouselScrollWidth);
        }
        return prevLeftValue - scrollDistance;
      });
      setCursorIndex(prevCursorIndex => prevCursorIndex + numGamesFitted);

      checkLoadMoreGames();
    }
  }, [checkLoadMoreGames, getScrollDistance, isScrollForwardDisabled, numGamesFitted]);

  const getIsGameOnScreen = useCallback(
    (gameIndex: number) => {
      return gameIndex >= cursorIndex && gameIndex < cursorIndex + numGamesFitted;
    },
    [cursorIndex, numGamesFitted]
  );

  const onScrollHandlerThrottled = useCallback(
    (onScrollHandler: () => void) => {
      if (!isScrolling) {
        setIsScrolling(true);
        onScrollHandler();
        setTimeout(() => {
          setIsScrolling(false);
        }, 200);
      }
    },
    [isScrolling]
  );

  const carouselContainerRef = useRef<HTMLDivElement>(null);
  useHorizontalScrollTracker({
    scrollPosition: -carouselLeftValue,
    page,
    gameSetTypeId: sort.topicId,
    gameSetTargetId: getSortTargetId(sort),
    wrapperRef: carouselContainerRef,
    sortPosition: positionId
  });

  const listContainerClassName = useMemo(() => {
    if (componentType === TComponentType.GridTile) {
      return 'game-carousel games-page-carousel wide-game-tile-carousel';
    }

    return 'hlist games game-cards game-tile-list';
  }, [componentType]);

  return (
    <div
      data-testid='game-carousel'
      ref={carouselContainerRef}
      className='horizontal-scroller games-list'>
      <div ref={carouselRef} className='clearfix horizontal-scroll-window'>
        <div
          ref={carouselScrollRef}
          className='horizontally-scrollable'
          style={{
            left: `${carouselLeftValue}px`
          }}>
          <ul ref={gamesContainerRef} className={listContainerClassName}>
            {gameData.map((data, gameIndex) =>
              componentType === TComponentType.GridTile ? (
                <GameTileTypeMap
                  key={data.universeId}
                  ref={tileRef}
                  id={gameIndex}
                  isOnScreen={getIsGameOnScreen(gameIndex)}
                  page={page}
                  gameData={data}
                  translate={translate}
                  buildEventProperties={buildEventProperties}
                  componentType={componentType}
                  playerCountStyle={playerCountStyle}
                  playButtonStyle={playButtonStyle}
                />
              ) : (
                <li key={data.universeId} className='list-item game-card game-tile'>
                  <GameTileTypeMap
                    ref={tileRef}
                    id={gameIndex}
                    isOnScreen={getIsGameOnScreen(gameIndex)}
                    page={page}
                    gameData={data}
                    className='game-card-container'
                    translate={translate}
                    buildEventProperties={buildEventProperties}
                  />
                </li>
              )
            )}
          </ul>
        </div>
        <ScrollerBar
          scrollClassNames={classNames('scroller', 'prev', { disabled: isScrollBackDisabled })}
          scrollIconClassName='icon-games-carousel-left'
          isDisabled={isScrollBackDisabled}
          scroll={() => onScrollHandlerThrottled(onScrollToPrev)}
        />
        <ScrollerBar
          scrollClassNames={classNames('scroller', 'next', { disabled: isScrollForwardDisabled })}
          scrollIconClassName='icon-games-carousel-right'
          isDisabled={isScrollForwardDisabled}
          scroll={() => onScrollHandlerThrottled(onScrollToNext)}
        />
      </div>
    </div>
  );
};

GameCarouselHorizontalScroll.defaultProps = {
  loadMoreGames: undefined,
  componentType: undefined,
  itemsPerRow: undefined,
  playerCountStyle: undefined,
  playButtonStyle: undefined
};

export default GameCarouselHorizontalScroll;

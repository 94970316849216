import React, { useEffect, useRef } from 'react';
import angular from 'angular';
import { fireEvent } from 'roblox-event-tracker';
import { homePage } from '../common/constants/configConstants';
import peopleListModule, {
  templates
} from '../../../../../Roblox.Friends.WebApp/Roblox.Friends.WebApp/js/angular/peopleList/peopleListEntry';

const AngularFriendsCarousel = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef?.current) {
      try {
        angular.bootstrap(containerRef.current, [
          peopleListModule.name,
          (templates as angular.IModule).name
        ]);
      } catch {
        fireEvent(homePage.friendsCarouselAngularBootstrapErrorEvent);
      }
    }
  }, []);

  return (
    <div className='friend-carousel-container'>
      <div ref={containerRef} people-list-container='' />
    </div>
  );
};

export default AngularFriendsCarousel;
